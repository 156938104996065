import React, { useState } from 'react';
import './Chatbot.css';
import ChatbotAvatar from './ChatbotAvatar';

const Chatbot = () => {
  const [isMinimized, setIsMinimized] = useState(false);
  const [chatFlow, setChatFlow] = useState('initial');
  const [userInput, setUserInput] = useState('');

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  const handleUserInput = (e) => {
    setUserInput(e.target.value);
  };

  const submitUserInput = () => {
    switch (chatFlow) {
      case 'initial':
        if (userInput.toLowerCase().includes('repair')) {
          setChatFlow('selectIssue');
        }
        break;
      case 'selectIssue':
        if (userInput.toLowerCase().includes('screen')) {
          setChatFlow('causeAnalysis');
        }
        // Add more conditions based on different issues
        break;
      case 'causeAnalysis':
        // Handle cause analysis logic
        setChatFlow('modelSpecification');
        break;
      case 'modelSpecification':
        // Handle model specification logic
        setChatFlow('partsSpecification');
        break;
      case 'partsSpecification':
        // Handle parts specification logic
        setChatFlow('warrantyClaimCheck');
        break;
      // Continue for other cases
      default:
        break;
    }
    setUserInput('');
  };

  const renderChatContent = () => {
    switch (chatFlow) {
      case 'initial':
        return <p>Hello! I'm here to help with your phone repair needs. Please type 'repair' to get started.</p>;
      case 'selectIssue':
        return <p>What issue are you facing? (e.g., Screen Issue, Battery Issue, etc.)</p>;
      case 'causeAnalysis':
        return <p>What caused the issue? (e.g., Drop, Water Damage, etc.)</p>;
      case 'modelSpecification':
        return <p>Please specify your phone model. (e.g., iPhone X, Samsung S23, etc.)</p>;
      case 'partsSpecification':
        return <p>Please choose the parts you would like to replace. (e.g., Screen, Battery, etc.)</p>;
      case 'warrantyClaimCheck':
        return <p>Is your device under warranty? (Yes/No)</p>;
      // Add more cases as per the chat flow stages
      default:
        return <p>How can I assist you today?</p>;
    }
  };

  return (
    <div className={`chatbot-container ${isMinimized ? 'minimized' : ''}`}>
      <div className="chatbot-header">
        <ChatbotAvatar />
        <button className="minimize-btn" onClick={toggleMinimize}>
          {isMinimized ? '▲' : '▼'}
        </button>
      </div>
      {!isMinimized && (
        <div className="chatbot-content">
          {renderChatContent()}
          <input 
            type="text" 
            value={userInput} 
            onChange={handleUserInput} 
            placeholder="Type your message here..." 
          />
          <button onClick={submitUserInput}>Send</button>
        </div>
      )}
    </div>
  );
};

export default Chatbot;
