import React from 'react';
import './TrainingPage.css';

const TrainingPage = () => {
    return (
        <div className="training-page">
            <h1 className="title">Mobile Phone Repair and Screen Refurbishing Training</h1>

            <section className="toc">
                <h2>Table of Contents</h2>
                <ul>
                    <li><a href="#introduction">Introduction</a></li>
                    <li><a href="#pbl">Problem-Based Learning</a></li>
                    <li><a href="#curriculum">Comprehensive Curriculum</a></li>
                    <li><a href="#environment">Supportive Learning Environment</a></li>
                    <li><a href="#internship">Internship Opportunities</a></li>
                    <li><a href="#online">Online Learning Resources</a></li>
                    <li><a href="#conclusion">Conclusion</a></li>
                    <li><a href="#contact">Contact Information</a></li>
                </ul>
            </section>

            <section id="introduction">
                <h2>Introduction</h2>
                <p>
                    The mobile phone industry in Nepal is growing at an unprecedented rate, with millions of devices in use across the country. This surge in mobile phone usage has created a significant demand for skilled technicians who can repair and maintain these devices. However, there remains a gap in the market for high-quality, practical training in mobile phone repair and refurbishing.
                </p>
                <p>
                    Mobizilla recognizes this gap and has launched a state-of-the-art Mobile Phone Repair and Screen Refurbishing Training Program in Kathmandu to address it. Our program is designed to provide hands-on training that equips participants with the skills needed to excel in the mobile repair industry. Whether you are a beginner or an experienced technician looking to enhance your skills, our training program is tailored to meet your needs.
                </p>
            </section>

            <section id="pbl">
                <h2>Problem-Based Learning</h2>
                <p>
                    Mobizilla's training methodology focuses on problem-based learning, where students tackle real-life repair scenarios. This approach helps you develop critical thinking and problem-solving skills that are essential for a successful career in mobile repair.
                </p>
            </section>

            <section id="curriculum">
                <h2>Comprehensive Curriculum</h2>
                <p>
                    Our curriculum is designed to cover all aspects of mobile repair, from basic electronics to advanced chip-level repairs. This ensures that you are fully equipped to handle any challenge you may encounter in the field.
                </p>
            </section>

            <section id="environment">
                <h2>Supportive Learning Environment</h2>
                <p>
                    We offer a tech-friendly learning environment with counseling and support services to keep you motivated and inspired throughout the course.
                </p>
            </section>

            <section id="internship">
                <h2>Internship Opportunities</h2>
                <p>
                    Mobizilla provides internship opportunities, giving you the chance to gain real-world experience while still in training. This hands-on experience is invaluable in preparing you for a successful career in mobile repair.
                </p>
            </section>

            <section id="online">
                <h2>Online Learning Resources</h2>
                <p>
                    In addition to our in-person training, Mobizilla offers free access to mobile repair software and video tutorials. These resources allow you to continue learning and refining your skills even after the course is complete.
                </p>
            </section>

            <section id="conclusion">
                <h2>Conclusion</h2>
                <p>
                    Mobizilla’s Mobile Phone Repair and Screen Refurbishing Training Program is designed to close the skills gap in Nepal’s rapidly growing mobile repair industry. With a strong focus on practical, hands-on training, this program equips participants with the knowledge and skills needed to succeed in the field. Whether you are starting your career or looking to enhance your existing skills, Mobizilla’s training program offers the comprehensive education you need to thrive in today’s competitive market.
                </p>
            </section>

            <section id="contact">
                <h2>Contact Information</h2>
                <p>Phone: 9808450458</p>
                <p>Contact Person: Kushal Shrestha</p>
                <p>Location: [Insert Training Center Location]</p>
            </section>
        </div>
    );
};

export default TrainingPage;
