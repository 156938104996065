import React from 'react';
import './Reviews.css';
import reviewImage from '../../assets/images/review-image.png'; // Adjust the path if necessary

const Reviews = () => {
  return (
    <div className="reviews-container">
      <h2>Customer Reviews</h2>
      <div className="review">
        <img src={reviewImage} alt="Customer Review" />
        <p>"Mobizilla fixed my phone in no time! The service was excellent, and the staff were very friendly. Highly recommend!"</p>
      </div>
      {/* Add more reviews here as needed */}
    </div>
  );
};

export default Reviews;
