import React, { useEffect, useState } from 'react';
import './StickyHeader.css';

const StickyHeader = () => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header id="sticky-header" className={`sticky-header ${isSticky ? 'visible' : ''}`}>
      <div className="sticky-header-content">
        <nav>
          <a href="/">Home</a>
          <a href="/buy">Buy</a>
          <a href="/sell">Sell</a>
          <a href="/repair">Repair</a>
        </nav>
      </div>
    </header>
  );
};

export default StickyHeader;
