import React from 'react';
import './HeroSection.css';
import HeroSectionRow from './HeroSectionRow';

import buySlide1 from '../../assets/images/buy-slide1.jpg';
import buySlide2 from '../../assets/images/buy-slide2.jpg';
import buySlide3 from '../../assets/images/buy-slide3.jpg';
import sellSlide1 from '../../assets/images/sell-slide1.jpg';
import sellSlide2 from '../../assets/images/sell-slide2.jpg';
import repairSlide1 from '../../assets/images/repair-slide1.jpg';
import repairSlide2 from '../../assets/images/repair-slide2.jpg';
import repairSlide3 from '../../assets/images/repair-slide3.jpg';
import buyLogo from '../../assets/images/buy-logo.png';
import sellLogo from '../../assets/images/sell-logo.png';
import repairLogo from '../../assets/images/repair-logo.png';

const HeroSection = () => {
  return (
    <div className="hero-section-container">
      <HeroSectionRow 
        slideImages={[repairSlide1, repairSlide2, repairSlide3]}
        logoImage={repairLogo}
        buttonText="Repair"
        linkTo="/repair"
      />
      <HeroSectionRow 
        slideImages={[sellSlide1, sellSlide2]} 
        logoImage={sellLogo}
        buttonText="Sell"
        linkTo="/sell"
      />
      <HeroSectionRow 
        slideImages={[buySlide1, buySlide2, buySlide3]}
        logoImage={buyLogo}
        buttonText="Buy"
        linkTo="/buy"
      />
    </div>
  );
};

export default HeroSection;
