import React from 'react';
import './RepairForm.css';

const RepairForm = () => {
  return (
    <div className="repair-form-container">
      <h2>Ready to Book Your Repair?</h2>
      <form>
        <label>Device Type</label>
        <input type="text" placeholder="e.g., iPhone, Samsung Galaxy" />
        
        <label>Describe the Issue</label>
        <textarea placeholder="e.g., Cracked screen, Battery issue"></textarea>
        
        <label>Contact Information</label>
        <input type="email" placeholder="Enter your email" />
        
        <label>Preferred Date for Repair</label>
        <input type="date" />
        
        <button type="submit">Book Repair</button>
      </form>
    </div>
  );
};

export default RepairForm;
