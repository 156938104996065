import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import StickyHeader from './components/StickyHeader/StickyHeader';
import Training from './components/Training/TrainingPage';
import Footer from './components/Footer/Footer';
import HeroSection from './components/HeroSection/HeroSection';
import RepairServices from './components/RepairServices/RepairServices';
import Repair from './components/RepairServices/RepairSection';
import YouTubeSection from './components/YouTubeSection/YouTubeSection';
import Reviews from './components/Reviews/Reviews';
import Buy from './components/Buy/BuyPage';
import Sell from './components/Sell/Sell';
import RepairPage from './components/RepairServices/RepairPage';
import Contact from './components/Contact/Contact';
import Dashboard from './components/Dashboard/Dashboard';
import Chatbot from './components/RepairServices/Chatbot';
import HomePage from './components/HomePage/HomePage';
import BuyBack from './components/BuyBack/BuyBack';


const App = () => {
  return (
    <Router>
      <Header />
      <StickyHeader />
      <div className="main-content">
        <Routes>
          <Route 
            path="/" 
            element={
              <>
                <HeroSection />
                <HomePage />
                <RepairServices />
                <Repair />
                <YouTubeSection />
                <Reviews />  {/* Include the Reviews component here */}
                <Chatbot />
                <Training />
              </>
            } 
          />
          <Route path="/buy" element={<Buy />} />
          <Route path="/sell" element={<Sell />} />
          <Route path="/repair" element={<RepairPage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/buyback" component={BuyBack} />
          <Route path="/training" element={<Training />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
