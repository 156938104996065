import React, { useState } from 'react';
import './RepairPage.css';
import Chatbot from '../RepairServices/Chatbot';
import CustomerReview from '../Shared/CustomerReview';

const reviews = [
  {
    name: 'John Doe',
    text: 'Great service! Highly recommend.',
    mediaType: 'image',
    mediaSrc: '/images/review1.jpg',
  },
  {
    name: 'Jane Smith',
    text: 'Quick and professional. Will come back!',
    mediaType: 'video',
    mediaSrc: '/videos/review1.mp4',
  },
];

const RepairPage = () => {
  const [step, setStep] = useState(1);
  const [deviceType, setDeviceType] = useState('');
  const [issueType, setIssueType] = useState('');
  const [cause, setCause] = useState('');
  const [partType, setPartType] = useState('');
  const [partQuality, setPartQuality] = useState('');
  const [estimatedCost, setEstimatedCost] = useState(null);
  const [repairTime, setRepairTime] = useState(null);
  const [appointmentConfirmed, setAppointmentConfirmed] = useState(false);

  const handleNextStep = () => {
    setStep(prevStep => prevStep + 1);
  };

  const handlePreviousStep = () => {
    setStep(prevStep => prevStep - 1);
  };

  const calculateCost = () => {
    let cost = 0;
    if (issueType === 'Screen Repair') {
      cost = partQuality === 'Original' ? 150 : 100;
    }
    setEstimatedCost(cost);
  };

  const estimateRepairTime = () => {
    setRepairTime('2 hours');
  };

  const confirmAppointment = () => {
    setAppointmentConfirmed(true);
  };

  return (
    <div className="repair-page-container">
      <h1>We Fix All Devices, All Problems</h1>
      <p>Our team of expert technicians is ready to diagnose and fix your device. Get started with our AI assistant below.</p>

      <Chatbot />

      {step === 1 && (
        <div>
          <h2>Select Issue Type</h2>
          <select value={issueType} onChange={(e) => setIssueType(e.target.value)}>
            <option value="">Select Issue Type</option>
            <option value="Broken Back Glass">Broken Back Glass</option>
            <option value="Display Issue">Display Issue</option>
            <option value="Camera Issue">Camera Issue</option>
            <option value="Battery Issue">Battery Issue</option>
          </select>
          <button onClick={handleNextStep}>Next</button>
        </div>
      )}

      {step === 2 && (
        <div>
          <h2>Determine Primary Cause</h2>
          <select value={cause} onChange={(e) => setCause(e.target.value)}>
            <option value="">Select Cause</option>
            <option value="Drop">Drop</option>
            <option value="Scratch">Scratch</option>
            <option value="Water Damage">Water Damage</option>
          </select>
          <button onClick={handlePreviousStep}>Back</button>
          <button onClick={handleNextStep}>Next</button>
        </div>
      )}

      {step === 3 && (
        <div>
          <h2>Select Parts for Replacement/Repair</h2>
          <select value={partType} onChange={(e) => setPartType(e.target.value)}>
            <option value="">Select Part</option>
            <option value="Back Glass">Back Glass</option>
            <option value="Front Glass">Front Glass</option>
            <option value="Display">Display</option>
          </select>

          <h3>Specify Parts Quality</h3>
          <select value={partQuality} onChange={(e) => setPartQuality(e.target.value)}>
            <option value="">Select Quality</option>
            <option value="Original">Original</option>
            <option value="GX">GX</option>
            <option value="Copy">Copy</option>
          </select>
          <button onClick={handlePreviousStep}>Back</button>
          <button onClick={handleNextStep}>Next</button>
        </div>
      )}

      {step === 4 && (
        <div>
          <h2>Cost Estimation</h2>
          <button onClick={calculateCost}>Calculate Cost</button>
          {estimatedCost && <p>Estimated Cost: ${estimatedCost}</p>}
          <button onClick={handlePreviousStep}>Back</button>
          <button onClick={handleNextStep}>Next</button>
        </div>
      )}

      {step === 5 && (
        <div>
          <h2>Repair Time Estimation</h2>
          <button onClick={estimateRepairTime}>Estimate Repair Time</button>
          {repairTime && <p>Estimated Repair Time: {repairTime}</p>}
          <button onClick={handlePreviousStep}>Back</button>
          <button onClick={handleNextStep}>Next</button>
        </div>
      )}

      {step === 6 && (
        <div>
          <h2>Appointment Booking</h2>
          <p>Confirm the details below:</p>
          <p>Device: {deviceType}</p>
          <p>Issue: {issueType}</p>
          <p>Part: {partType}</p>
          <p>Quality: {partQuality}</p>
          <p>Cost: ${estimatedCost}</p>
          <button onClick={confirmAppointment}>Confirm Appointment</button>
          {appointmentConfirmed && <p>Appointment Confirmed!</p>}
          <button onClick={handlePreviousStep}>Back</button>
        </div>
      )}

      <div>
        <h2>Customer Reviews</h2>
        <div className="customer-reviews">
          {reviews.map((review, index) => (
            <CustomerReview
              key={index}
              name={review.name}
              text={review.text}
              mediaType={review.mediaType}
              mediaSrc={review.mediaSrc}
            />
          ))}
        </div>
      </div>

      <div>
        <h2>Video Testimonials</h2>
        <div className="video-testimonials">
          {/* Add video testimonials component here */}
        </div>
      </div>

      <div>
        <h2>Get In Touch</h2>
        <div className="contact-info">
          {/* Add contact form or information here */}
        </div>
      </div>

      <div>
        <h2>Our Location</h2>
        <div className="map">
          {/* Embed Google Map here */}
        </div>
      </div>
    </div>
  );
};

export default RepairPage;
