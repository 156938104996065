import React from 'react';
import './BuyOverview.css';

const BuyOverview = () => {
  return (
    <div className="buy-overview-container">
      <div className="brand-card">
        <img src="/images/iPhone-buy-logo.png" alt="iPhone" />
        <h2>iPhone</h2>
        <p>Explore the latest iPhone models with cutting-edge technology and premium design.</p>
        <button>Shop Now</button>
      </div>
      <div className="brand-card">
        <img src="/images/Samsung-buy-logo.png" alt="Samsung" />
        <h2>Samsung</h2>
        <p>Discover Samsung’s innovative smartphones with advanced features.</p>
        <button>Shop Now</button>
      </div>
      {/* Add more brand cards for other models */}
    </div>
  );
};

export default BuyOverview;
