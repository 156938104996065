import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="column">
                    <h3>Contact Us</h3>
                    <ul>
                        <li>Newroad, Kathmandu</li>
                        <li>Email: mobizilla@mymobizilla.com</li>
                        <li>Phone: 015354999, 9808450458</li>
                    </ul>
                    <div className="social-icons">
                        <a href="https://twitter.com/mobizillalab" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
                        <a href="https://facebook.com/mobilzillarepairlab" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
                        <a href="https://instagram.com/mobizillarepairlab" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
                        <a href="https://youtube.com/@mobizillarepairlab" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube"></i></a>
                        <a href="https://linkedin.com/company/mobizilla" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a>
                    </div>
                </div>
                <div className="column">
                    <h3>Quick Links</h3>
                    <ul>
                        <li><a href="/about-us">About Us</a></li>
                        <li><a href="/contact-us">Contact Us</a></li>
                        <li><a href="/phone-gadgets">Phone & Gadgets</a></li>
                        <li><a href="/mobile-repairing">Mobile Repairing</a></li>
                        <li><a href="/blog">Our Blog</a></li>
                        <li><a href="/compare-phones">Compare Phones</a></li>
                    </ul>
                </div>
                <div className="column">
                    <h3>Our Location</h3>
                    <div className="location">
                        <iframe
                            src="https://maps.google.com/maps?q=Kathmandu&z=15&output=embed"
                            width="100%"
                            height="300"  
                            frameBorder="0"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            aria-hidden="false"
                            tabIndex="0"
                            title="Google Map"
                        ></iframe>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; Mobizilla 2024. All Rights Reserved.</p>
                <p><a href="/construction-notice">Website is under construction!</a></p>
            </div>
        </footer>
    );
}

export default Footer;
