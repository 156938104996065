import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import BuyBack from '../BuyBack/BuyBack'; // Import the BuyBack component
import './Sell.css'; // Add your Sell-specific CSS

const Sell = () => {
  return (
    <div className="sell-page-container">
      <ScrollAnimation animateIn="fadeInDown" duration={2}>
        <header className="sell-page-header">
          <h1>Sell Your Device to Mobizilla</h1>
          <p>Trade-in your old devices for instant cash or credit towards your next purchase.</p>
        </header>
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeInUp" delay={300}>
        <section className="buyback-section">
          <BuyBack /> {/* Integrate the BuyBack component */}
        </section>
      </ScrollAnimation>
    </div>
  );
};

export default Sell;
