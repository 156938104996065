// ChatbotAvatar.js
import React from 'react';
import './ChatbotAvatar.css';

const ChatbotAvatar = () => {
  return (
    <div className="chatbot-avatar">
      <img src="/mobizilla-frontend/src/assets/images/chatbot-avatar.png" alt="Chatbot Avatar"/>
    </div>
  );
};

export default ChatbotAvatar;
