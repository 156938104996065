import React from 'react';
import './YouTubeSection.css';

const YouTubeSection = () => {
  return (
    <div className="youtube-section">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/-hu-z_bwsMo" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/D1qtAK3V7Vc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/VNG2xMxtzWE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
  );
};

export default YouTubeSection;
