import React, { useEffect } from 'react';
import './BuyPage.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const BuyPage = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true,
    });
  }, []);

  return (
    <div className="buy-page">
      {/* Hero Section */}
      <section className="hero-section">
        <h1>Discover and Buy the Latest Smartphones</h1>
        <p>Explore top brands and find the best deals on the market.</p>
        <button className="start-shopping">Start Shopping</button>
      </section>

      {/* Our Brands Section */}
      <section className="our-brands" data-aos="fade-up">
        <h2>Our Brands</h2>
        <div className="brands-container">
          <div className="brand-card">
            <img src="/path-to-iPhone-image.jpg" alt="iPhone" />
            <h3>iPhone</h3>
            <p>Explore the latest iPhone models with cutting-edge technology and premium design.</p>
            <button>Shop Now</button>
          </div>
          <div className="brand-card">
            <img src="/path-to-Samsung-image.jpg" alt="Samsung" />
            <h3>Samsung</h3>
            <p>Discover Samsung's innovative smartphones with advanced features.</p>
            <button>Shop Now</button>
          </div>
        </div>
      </section>

      {/* Filter Products Section */}
      <section className="filter-products" data-aos="fade-up">
        <h2>Filter Products</h2>
        <div className="filter-container">
          <label htmlFor="price-range">Price Range:</label>
          <input type="range" id="price-range" name="price-range" min="100" max="1000" />
        </div>
      </section>

      {/* Compare Section */}
      <section className="compare-section" data-aos="fade-up">
        <h2>Compare Smartphones</h2>
        <div className="compare-container">
          <h3>Select Models to Compare</h3>
          <button className="compare-btn">iPhone 14 Pro</button>
          <button className="compare-btn">Samsung S23 Ultra</button>
        </div>
      </section>

      {/* Featured Products Section */}
      <section className="featured-products" data-aos="fade-up">
        <h2>Featured Products</h2>
        {/* Add featured products components/cards here */}
      </section>
    </div>
  );
};

export default BuyPage;
