import React from 'react';

const CustomerReview = ({ name, text, mediaType, mediaSrc }) => {
  return (
    <div className="review-card">
      <h3>{name}</h3>
      <p>{text}</p>
      {mediaType === 'image' && <img src={mediaSrc} alt="review" />}
      {mediaType === 'video' && <video src={mediaSrc} controls />}
    </div>
  );
};

export default CustomerReview;  // Make sure this is correctly exported
