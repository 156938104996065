import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import './HomePage.css';
import BuyOverview from '../Buy/BuyOverview'; // Assuming this component is created
import Chatbot from '../RepairServices/Chatbot'; // Including Chatbot for support

const HomePage = () => {
  return (
    <div className="home-page-container">
      <ScrollAnimation animateIn="fadeInUp" duration={2}>
        <div className="logo-container">
          <img src="/images/logo.png" alt="Mobizilla Logo" className="logo" />
        </div>
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeInUp" delay={500}>
        <section id="services" className="services-section">
          <h2>Our Services</h2>
          <p>We offer the best mobile repair services, including screen replacement, battery replacement, and more.</p>
        </section>
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeInUp" delay={1000}>
        <section id="buy" className="buy-section">
          <h2>Buy Latest Smartphones</h2>
          <BuyOverview />
        </section>
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeInUp" delay={1500}>
        <section id="contact" className="contact-section">
          <h2>Contact Us</h2>
          <p>Get in touch with us to learn more about our services.</p>
          {/* Include a contact form or information here */}
        </section>
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeInUp" delay={2000}>
        <section id="chatbot" className="chatbot-section">
          <h2>Need Help?</h2>
          <Chatbot />
        </section>
      </ScrollAnimation>
    </div>
  );
};

export default HomePage;
