import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/header-logo.png';
import headIcon from '../../assets/images/head_animation.gif'; // Path to the head PNG
import accountVideo from '../../assets/videos/system-solid-8-account.mp4'; // Path to the login video
import './Header.css';

const Header = () => {
    return (
        <header id="main-header" className="main-header">
            <div className="header-left">
                <Link to="/" className="logo-link">
                    <img src={logo} alt="Mobizilla Logo" className="logo" />
                </Link>
            </div>
            <div className="header-center">
                <div className="search-bar">
                    <input type="text" placeholder="Search for services, products, or info..." />
                    <button type="submit"><i className="fas fa-search"></i></button>
                </div>
            </div>
            <div className="header-right">
                <div className="category-dropdown">
                    <button className="dropt">All Categories</button>
                    <div className="dropdown-content">
                        <Link to="/repair">Repair</Link>
                        <Link to="/sell">Sell</Link>
                        <Link to="/buy">Buy</Link>
                        <Link to="/training">Training</Link>
                        <Link to="/website">Website Development</Link>
                        <Link to="/software">Software Development</Link>
                        <Link to="/digital-marketing">Digital Marketing</Link>
                        <Link to="/inventory">Inventory Management</Link>
                        <Link to="/e-waste">E-Waste Management</Link>
                        <Link to="/franchise">Franchise Opportunities</Link>
                        <Link to="/consulting">Consulting Services</Link>
                    </div>
                </div>
                <div className="video-icon-wrapper">
                    <video src={accountVideo} muted autoPlay loop className="video-icon"></video>
                </div>
                <div className="social-media-dropdown">
                    <img src={headIcon} alt="User Icon" className="head-icon" />
                    <div className="dropdown-content">
                        <a href="https://twitter.com/mobizillalab" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
                        <a href="https://facebook.com/mobilzillarepairlab" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
                        <a href="https://instagram.com/mobizillarepairlab" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
                        <a href="https://youtube.com/@mobizillarepairlab" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube"></i></a>
                        <a href="https://linkedin.com/company/mobizilla" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
