import React from 'react';
import './RepairSection.css';
import serviceIcon1 from '../../assets/images/service-icon1.png';  // Update the path accordingly
import serviceIcon2 from '../../assets/images/service-icon2.png';  // Update the path accordingly

const RepairSection = () => {
  return (
    <div className="repair-section">
      <h1>We Fix All Devices, All Problems</h1>
      <div className="repair-services">
        <div className="repair-service">
          <img src={serviceIcon1} alt="Service Icon 1" />
          <h2>Cell Phone</h2>
          <p>We repair all types of cell phones.</p>
        </div>
        <div className="repair-service">
          <img src={serviceIcon2} alt="Service Icon 2" />
          <h2>Tablet</h2>
          <p>We repair all types of tablets.</p>
        </div>
        {/* Add more services as needed */}
      </div>
    </div>
  );
};

export default RepairSection;
