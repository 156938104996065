import React from 'react';
import './RepairServices.css';
import RepairSection from './RepairSection';
import RepairForm from './RepairForm';

const RepairServices = () => {
  return (
    <div className="repair-services-container">
      <RepairSection />
      <RepairForm />
    </div>
  );
};

export default RepairServices;
