import React, { useState } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import './BuyBack.css';

const BuyBack = () => {
  const [deviceBrand, setDeviceBrand] = useState('');
  const [deviceModel, setDeviceModel] = useState('');
  const [deviceCondition, setDeviceCondition] = useState('');
  const [quote, setQuote] = useState(null);

  const handleGetQuote = () => {
    // Example logic to calculate quote based on brand, model, and condition
    let calculatedQuote = 0;
    if (deviceCondition === 'Good') {
      calculatedQuote = 300; // Example amount
    } else if (deviceCondition === 'Fair') {
      calculatedQuote = 200;
    } else if (deviceCondition === 'Poor') {
      calculatedQuote = 100;
    }
    setQuote(calculatedQuote);
  };

  return (
    <div className="buyback-page-container">
      <ScrollAnimation animateIn="fadeInDown" duration={2}>
        <header className="buyback-page-header">
          <h1>Sell Your Device to Mobizilla</h1>
          <p>Get an instant quote and sell your old device in just a few steps.</p>
        </header>
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeInUp" delay={300}>
        <section className="sell-device-section">
          <h2>Select Your Device</h2>
          <div className="device-selection">
            <label htmlFor="brand">Device Brand:</label>
            <select
              id="brand"
              value={deviceBrand}
              onChange={(e) => setDeviceBrand(e.target.value)}
            >
              <option value="">Select Brand</option>
              <option value="Apple">Apple</option>
              <option value="Samsung">Samsung</option>
              <option value="Xiaomi">Xiaomi</option>
              {/* Add more brands as needed */}
            </select>

            <label htmlFor="model">Device Model:</label>
            <select
              id="model"
              value={deviceModel}
              onChange={(e) => setDeviceModel(e.target.value)}
              disabled={!deviceBrand} // Disable until a brand is selected
            >
              <option value="">Select Model</option>
              {/* Conditional rendering based on selected brand */}
              {deviceBrand === 'Apple' && (
                <>
                  <option value="iPhone 12">iPhone 12</option>
                  <option value="iPhone 13">iPhone 13</option>
                  {/* Add more Apple models */}
                </>
              )}
              {deviceBrand === 'Samsung' && (
                <>
                  <option value="Galaxy S20">Galaxy S20</option>
                  <option value="Galaxy S21">Galaxy S21</option>
                  {/* Add more Samsung models */}
                </>
              )}
              {/* Add models for other brands */}
            </select>

            <label htmlFor="condition">Device Condition:</label>
            <select
              id="condition"
              value={deviceCondition}
              onChange={(e) => setDeviceCondition(e.target.value)}
            >
              <option value="">Select Condition</option>
              <option value="Good">Good</option>
              <option value="Fair">Fair</option>
              <option value="Poor">Poor</option>
            </select>

            <button onClick={handleGetQuote} className="get-quote-button">
              Get Quote
            </button>

            {quote !== null && (
              <div className="quote-result">
                <h3>Estimated Quote: ${quote}</h3>
                <p>
                  If you are satisfied with the quote, proceed to book an appointment.
                </p>
                <button className="book-appointment-button">
                  Book Appointment
                </button>
              </div>
            )}
          </div>
        </section>
      </ScrollAnimation>
    </div>
  );
};

export default BuyBack;
