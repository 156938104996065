import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import './HeroSection.css';

const HeroSectionRow = ({ slideImages, logoImage, buttonText, linkTo }) => {
  const [currentSlide, setCurrentSlide] = React.useState(0);

  const nextSlide = React.useCallback(() => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slideImages.length);
  }, [slideImages.length]);

  React.useEffect(() => {
    const interval = setInterval(nextSlide, 3000); // Change slide every 3 seconds
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [nextSlide]);

  return (
    <motion.div 
      className="hero-section"
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      <motion.div 
        className="slider-wrapper"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 0.5 }}
      >
        <img
          src={slideImages[currentSlide]}
          alt={buttonText}
          className="slider-image"
        />
      </motion.div>
      <motion.div 
        className="logo-wrapper"
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5, delay: 1 }}
      >
        <Link to={linkTo}>
          <motion.img
            src={logoImage}
            alt={buttonText}
            className="logo-wrapper__image"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
          />
        </Link>
        <Link to={linkTo}>
          <motion.button 
            className="hero-btn"
            whileHover={{ scale: 1.05, backgroundColor: '#0056b3' }}
            whileTap={{ scale: 0.95 }}
          >
            {buttonText}
          </motion.button>
        </Link>
      </motion.div>
    </motion.div>
  );
};

HeroSectionRow.propTypes = {
  slideImages: PropTypes.arrayOf(PropTypes.string).isRequired,
  logoImage: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
};

export default HeroSectionRow;
